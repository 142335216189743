import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/xunCompany'


const listcompanyApi =(params)=>getAction(baseURL+"/list",params);

const addcompanyApi=(params)=>postAction(baseURL+"/add",params);

const editcompanyApi=(params)=>putAction(baseURL+"/edit",params);

const deletecompanyByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

const selectcompanyByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);

const selectcompanyByIdStatusApi=(params)=>getByIdAction(baseURL+'/selectByIdStatus',params);

const editcompanyStatusApi=(params)=>getByIdAction(baseURL+'/editStatus',params);

export{
    selectcompanyByIdApi,
    deletecompanyByIdApi,
    editcompanyApi,
    addcompanyApi,
    listcompanyApi,
    selectcompanyByIdStatusApi,
    editcompanyStatusApi
}
