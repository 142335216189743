<template>
  <div class="app-container">
    <!-- 配置区域 -->
    <div>
      <el-card>
        <div style="float:right">
          <el-button
            type="primary"
            style="margin:-20px 20px 10px 10px"
            @click="editCompanyButton"
          >保存</el-button>
        </div>
      </el-card>
      <el-form :model="addFrom" layout="inline" :inline="true">
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="名称" width="120px" prop="companyName">
              <el-input width="20px" v-model="addFrom.companyName" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="全称" width="120px" prop="companyDetailedName">
              <el-input width="20px" v-model="addFrom.companyDetailedName" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公司性质" width="120px" prop="companyNature">
              <el-input width="20px" v-model="addFrom.companyNature" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="入职时间" prop="inductionData">
              <el-date-picker
                v-model="addFrom.inductionData"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发放时间" prop="payTime">
              <el-input-number v-model="addFrom.payTime"></el-input-number>号
            </el-form-item>
          </el-col>
          <!-- error -->
          <el-col :span="8">
            <el-form-item label="绑定卡" width="120px" prop="bankId">
              <el-select
                @change="AddSelectDept"
                v-model="addFrom.bankId"
                placeholder="请选择"
                style="width:70%"
              >
                <el-option
                  v-for="item in bankIdList"
                  :key="item.id"
                  :label="item.subbranch"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="税号" width="120px" prop="dutyParagraph">
              <el-input width="20px" v-model="addFrom.dutyParagraph" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="抬头" width="120px" prop="companyRise">
              <el-input width="20px" v-model="addFrom.companyRise" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规模" width="120px" prop="dutyScale">
              <el-input width="20px" v-model="addFrom.dutyScale" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="地区" prop="areaCode">
              <el-cascader
                :checkStrictly="true"
                style="width:500px"
                v-model="areaValue"
                :options="areaList"
                :props="{ value: 'areaCode', label: 'areaName'}"
                @change="handleChange"
                filterable
                ref="cascader"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="详细地址" prop="detailedAddress">
              <el-input v-model="addFrom.detailedAddress" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="经营范围" prop="businessScope">
              <el-input type="textarea" autosize :rows="2" v-model="addFrom.businessScope" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="备注" width="120px" prop="remark">
              <el-input type="textarea" autosize v-model="addFrom.remark" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 配置区域 -->
    <div>
      <el-form :model="queryParams" layout="inline" :inline="true">
        <el-row>
          <el-col :md="6" :sm="24" style="width:70%">
            <el-button type="primary" @click="addCompayButton()">新增历史</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!--     列表区域   -->
    <el-table :data="dataList" row-key="id" border>
      <el-table-column label="序号" align="center" width="65">
        <template slot-scope="scope">
          <el-radio
            :label="scope.$index"
            v-model="radio"
            @change.native="getCurrentRow(scope.row)"
            :disabled="scope.row.yearId==null?true:false"
          />
        </template>
      </el-table-column>
      <el-table-column label="名称" align="center" prop="companyName" />
      <el-table-column label="入职时间" align="center" prop="inductionData" />
      <el-table-column
        label="状态"
        align="center"
        prop="companyStatus"
        :formatter="hoursStatusFormat"
      />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            :disabled="scope.row.companyStatus==1?false:true"
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="editButton(scope.row)"
          >编辑</el-button>
          <el-button
            :disabled="scope.row.companyStatus==1?false:true"
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="editStatusButton(scope.row)"
          >设置使用</el-button>
        </template>
      </el-table-column>
    </el-table>

    
    <addBasic ref="addBasicFrom" />
  </div>
</template>
<script>
import { getAreaDataApi } from "@/api/system/mune/menuCoupon.js";
import { getBankInfoList } from "@/api/capital/bank/bank";
import addBasic from "./addBasic";
import {
  listcompanyApi,
  selectcompanyByIdStatusApi,
  editcompanyStatusApi,
  addcompanyApi,
  editcompanyApi
} from "@/api/my/company/company";
export default {
  components: {
    addBasic
  },
  data() {
    return {
      // 表单参数
      form: {},
      addFrom: {},
      dataList: [],
      // 表单校验
      rules: {},
      bankIdList: [],
      areaValue: [],
      // ["820000", "820006"],
      areaList: [],
      bankFrom: {},
      queryParams: {
        current: 1,
        pageSize: 10,
        total: 1
      },
      radio: null
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.listDictApiButton();
      this.getBankInfoListButton();
      this.areaDataList();
      this.selectcompanyByIdStatusApiButton();
    },
    AddSelectDept() {},
    handleChange() {},
    editCompanyButton() {
      this.addFrom.companyStatus = 2;
      //特殊处理
      console.log(this.areaValue);
      this.addFrom.provinceCode = this.areaValue[0];
      this.addFrom.cityCode = this.areaValue[1];
      this.addFrom.areaCode = this.areaValue[2];
      console.log(this.addFrom);
      if (this.addFrom.id == undefined || this.addFrom.id == null) {
        addcompanyApi(this.addFrom).then(res => {
          this.selectcompanyByIdStatusApiButton();
        });
      } else {
        editcompanyApi(this.addFrom).then(res => {
          this.selectcompanyByIdStatusApiButton();
        });
      }
    },
    areaDataList() {
      getAreaDataApi({}).then(res => {
        this.areaList = res.result;
      });
    },

    getBankInfoListButton() {
      getBankInfoList({ bankTypeList: [2, 5] }).then(res => {
        this.bankIdList = res.result.records;
      });
    },
    selectcompanyByIdStatusApiButton() {
      selectcompanyByIdStatusApi().then(res => {
        if (null != res.result) {
          this.addFrom = res.result;
          this.areaValue = res.result.areaList;
        }
      });
    },
    listDictApiButton() {
      listcompanyApi().then(res => {
        this.dataList = res.result.records;
      });
    },
    addCompayButton() {
      this.$refs.addBasicFrom.show(null, 1);
    },
    editButton(row) {
      this.$refs.addBasicFrom.show(row.id, 2);
    },
    editStatusButton(row) {
      editcompanyStatusApi(row.id).then(res => {
        this.selectcompanyByIdStatusApiButton();
        this.listDictApiButton();
      });
    },
    hoursStatusFormat(row, column) {
      if (row.companyStatus == 1) {
        return "失效";
      }
      if (row.companyStatus == 2) {
        return "使用中";
      }
    }
  }
};
</script>
<style lang="less"  scoped>
.el-cascader-panel {
  height: 60vh;
  .el-scrollbar__wrap {
    height: 100%;
  }
}
</style>