<template>
  <el-drawer
    :title="title"
    :before-close="handleClose"
    :visible.sync="dialog"
    direction="rtl"
    custom-class="demo-drawer"
    ref="drawer"
    size="50%"
  >
    <!-- 配置区域 -->
    <div>
      <el-form :model="addFrom" layout="inline" :inline="true">
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="名称" width="120px" prop="companyName">
              <el-input width="20px" v-model="addFrom.companyName" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="全称" width="120px" prop="companyDetailedName">
              <el-input width="20px" v-model="addFrom.companyDetailedName" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公司性质" width="120px" prop="companyNature">
              <el-input width="20px" v-model="addFrom.companyNature" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="入职时间" prop="inductionData">
              <el-date-picker
                v-model="addFrom.inductionData"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发放时间" prop="payTime">
              <el-input-number v-model="addFrom.payTime"></el-input-number>号
            </el-form-item>
          </el-col>
          <!-- error -->
          <el-col :span="8">
            <el-form-item label="绑定卡" width="120px" prop="bankId">
              <el-select
                @change="AddSelectDept"
                v-model="addFrom.bankId"
                placeholder="请选择"
                style="width:70%"
              >
                <el-option
                  v-for="item in bankIdList"
                  :key="item.id"
                  :label="item.subbranch"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

    

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="税号" width="120px" prop="dutyParagraph">
              <el-input width="20px" v-model="addFrom.dutyParagraph" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="抬头" width="120px" prop="companyRise">
              <el-input width="20px" v-model="addFrom.companyRise" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规模" width="120px" prop="dutyScale">
              <el-input width="20px" v-model="addFrom.dutyScale" />
            </el-form-item>
          </el-col>
        </el-row>
            <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="地区" prop="areaCode">
              <el-cascader
                :checkStrictly="true"
                style="width:400px"
                v-model="areaValue"
                :options="areaList"
                :props="{ value: 'areaCode', label: 'areaName'}"
                @change="handleChange"
                filterable
                ref="cascader"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="详细地址" prop="detailedAddress">
              <el-input v-model="addFrom.detailedAddress" />
            </el-form-item>
          </el-col>
        </el-row>

         <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="离职时间" prop="leaveData">
              <el-date-picker
                v-model="addFrom.leaveData"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
         </el-row>

        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="经营范围" prop="businessScope">
              <el-input type="textarea" autosize :rows="2" v-model="addFrom.businessScope" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="备注" width="120px" prop="remark">
              <el-input type="textarea" autosize v-model="addFrom.remark" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelForm">取 消</el-button>
        <el-button type="primary" @click="addHoursButton">确 定</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import {
  selectcompanyByIdApi,
  listcompanyApi,
  selectcompanyByIdStatusApi,
  editcompanyStatusApi,
  addcompanyApi,
  editcompanyApi
} from "@/api/my/company/company";
export default {
  data() {
    return {
      dialog: false,
      editShow: false,
      title: "",
      // 表单参数
      addFrom: {},
      // 表单校验
      id: null,
      type: 1,
      areaList: [],
      areaValue: [],
      bankIdList: []
    };
  },
  methods: {
    show(id, type) {
      this.areaList = this.$parent.areaList;
      this.bankIdList = this.$parent.bankIdList;
      this.dialog = true;
      if (type == 2) {
        this.selectByIdButton(id);
        this.editShow = true;
        this.title = "编辑";
      }
      if (type == 1) {
        this.title = "新增";
      }
      this.type = type;
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.dialog = false;
      this.editShow = false;
      this.addFrom = {};
      this.$parent.listDictApiButton();
    },
    addHoursButton() {
      this.addFrom.provinceCode = this.areaValue[0];
      this.addFrom.cityCode = this.areaValue[1];
      this.addFrom.areaCode = this.areaValue[2];
      if (this.type == 1) {
        this.addFrom.companyStatus = 1;
        addcompanyApi(this.addFrom).then(res => {
          this.cancelForm();
        });
      }
      if (this.type == 2) {
        editcompanyApi(this.addFrom).then(res => {
          this.cancelForm();
        });
      }
    },
    selectByIdButton(id) {
      selectcompanyByIdApi(id).then(res => {
        this.addFrom = res.result;
      });
    },
    handleChange() {
      console.log(this.areaValue);
    },
    AddSelectDept(){}
  }
};
</script>
<style scoped>
</style>